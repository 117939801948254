<template>
    <b-form-group>
        <label>{{ label }}</label>
        <div id="type-multi-selector">
            <multiselect id="types" label="label" track-by="value" :value="value" :multiple="true"
                :options="typeOptions" :showLabels="false" :searchable="false" :allowEmpty="true"
                :close-on-select="false" placeholder="Selecionar" class="with-border" @input="handleInput"
                :disabled="disabled">
                <template slot="caret">
                    <div class="chevron">
                        <ChevronDown />
                    </div>
                </template>
                <template #selection="{ values }">
                    <div v-if="value.length" class="selection">
                        <Ellipsis>{{ values.map(option => parseTypeToLabel(option)).join(', ') }}</Ellipsis>
                    </div>
                </template>
                <template #option="{ option }">
                    <div class="option">
                        <Check :value="value.some(type => type === option)" :label="option.label" />
                        <div class="mask"></div>
                    </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
        </div>
    </b-form-group>
</template>
<script>
import * as ITEM from '@items/utils/constants';
import { parseTypeToLabel } from '@items/utils/constants';


export default {
    props: {
        groupType: String,
        value: Array,
        grouped: Boolean,
        label: {
            type: String,
            default: 'Tipos'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        Ellipsis: () => import('@/components/General/Ellipsis'),
        Check: () => import('@/components/General/Check')
    },
    data() {
        const excludes = [];
        if (this.grouped) {
            excludes.push(ITEM.ITEM_TYPE_APPOINTMENT);
            excludes.push(ITEM.ITEM_TYPE_EXAM);
            excludes.push(ITEM.ITEM_TYPE_SURGICAL);
            excludes.push(ITEM.ITEM_TYPE_RETURN);
            excludes.push(ITEM.ITEM_TYPE_TELEMEDICINE);
        }

        return {
            typeOptions: ITEM.ITEM_TYPE_OPTIONS
                .filter(option => ITEM.getByTypesByGroup(this.groupType).includes(option.value))
                .filter(option => !excludes.includes(option.value))
                .map(option => ({
                    ...option,
                    $isDisabled: ITEM.notImplementedTypes.includes(option.value)
                })
                )
        };
    },
    methods: {
        parseTypeToLabel,
        handleInput(value) {
            this.$emit('input', value);
        }
    }
};
</script>
<style lang="scss">
.selection {
    width: 100%;
    padding-left: 12px;
    padding-right: 35px;
}

.option {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>